/*---------------------------------------
   2.17 Contact Details
-----------------------------------------*/
.contact-details {
    padding: rem(60px) rem(50px);
    background: $dm-xlight-bg;
    &__content {
        margin-bottom: rem(20px);
        padding: rem(30px) rem(25px);
        display: flex;
        align-items: flex-start;
        background: $white;
    }
    &__icon {
        min-width: rem(40px);
        height: rem(40px);
        margin-right: rem(15px);
        background: #000;
        flex: 1;
        &--one {
            background: url(../img/location-icon.png) center center no-repeat;
            background-size: contain;
        }
        &--two {
            background: url(../img/telephone-icon.png) center center no-repeat;
            background-size: contain;
        }
        &--three {
            background: url(../img/mailbox-icon.png) center center no-repeat;
            background-size: contain;
        }
    }
    &__info {
        flex: 4;
    }
}