/************** accordion part start***************/
.accordion_part{
    button{
        font-size: 21px;
        color: $dm-heading-text;
        font-weight: 600;
        line-height: 30px;
        padding: 0;
        @media #{$tab}{
            text-align: left !important;
            float: left !important;
        } 
    }
    .card{
        border-radius: 0;
        border: 0px solid transparent;
        margin-bottom: 0;
        background-color: transparent;
        .card-header {
            background-color: transparent;
            border-bottom: 1px solid #ddd;
            padding: 20px 0;
            margin-bottom: 0;
            
            
            h5{
                position: relative;
                z-index: 1;
                margin: 0;
                font-size: 21px;
                &:focus{
                    text-decoration: none;
                }
               
                button.btn.btn-link{
                    &:after{
                        position: absolute;
                        content: "\e648";
                        top: 0;
                        right: 12px;
                        height: auto;
                        font-family: 'themify';
                        transition: all 1s;
                        font-size: 16px;
                        color: #262626;
                    }
                }
                button.btn.btn-link.collapsed{
                    &:after{
                        content: "\e64b";
                        color: #262626;
                        font-size: 16px;
                    }
                    &:focus{
                        text-decoration: none;
                    }
                }
            }
        }
        .card-body{
            color: #000;
            padding: 0 0 30px;
            h5{
                font-size: 18px;
            }
            p{
                font-size: 16px;
                color: $p_color;
            }
        }
    }
  
    button{
        &:focus{
            text-decoration: none;
        }
    }
    .btn-link:hover {
        color: #182028;
        text-decoration: none;
    }
    .btn_1{
       
        background-image: linear-gradient(to right, #f26922 0%, #f6862a 50%, #f26922 100%);
        background-size: 200% auto;
        padding: 12px 35px;
        display: inline-block;
        color: $white;
        text-transform: capitalize;
        margin-top: 25px;
        cursor: pointer;
        transition: all 1s;
        &:hover{
            box-shadow: 0px 20px 50px 0 rgba(241, 89, 34, 0.5);
            text-decoration: none;
        }

    }
    .single_accordion_part{
        h5{
            font-size: 18px;
            line-height: 30px;
            margin: 32px 0;
            text-decoration: none;
        }
        p{
            margin-bottom: 0;
        }
        ul{
            padding: 0 17px 0;
            color: $p_color;
            li{
                padding: 6px 0;
            }
        }
    }
       
}