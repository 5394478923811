/*---------------------------------------
   2.3 Banner Style
-----------------------------------------*/
.banner {
    padding: rem(100px) 0 rem(135px);
    &--contact {
        background: url(../img/cntact-banner.png) center center no-repeat;
        background-size: cover;
        &-title {
            color: $white;
            font-size: 30px;
            @include lg-screen {
                font-size: 72px;
            }
        }
    }
    &__partner {
        background: url(../img/cntact-banner.png) center center no-repeat;
        background-size: cover;
        &-title {
            color: $white;
            font-size: 30px;
            @include lg-screen {
                font-size: 72px;
            }
        }
    }
    &--fees {
        background: url(../img/fees.jpg) center center no-repeat;
        background-size: cover;
        &-title {
            color: $white;
        }
    }
    &_about-bg{
        background-image: url(../img/about-banner.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
    }
    &__img {
        text-align: center;
        &-is {
            width: 70%;
            @include md-screen {
                width: 92%;
            }
            @include xl-screen {
                width: 80%;
            }
        }
    }
    &__content {
        text-align: center;
        &-head {
            padding: rem(80px) 0 rem(30px);
        }
        &-title {
            font-size: rem(40px);
            @include lg-screen {
                font-size: rem(60px);
            }
            @include xl-screen {
                font-size: rem(72px);
            }
        }
        &-btn {
            width: 100%;
            text-align: center;
            @include md-screen {
                text-align: left;
            }
        }
    }
    
}
.terms_bg {
    background-image: url(../img/terms-banner.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
}
.privacy_bg {
    background-image: url(../img/privacy-policy_.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
}
.faq_bg {
    background-image: url(../img/faqs.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover; 
}
.bg_overlay{
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.3;
        z-index: -1;
    }
}