/*---------------------------------------
   3.1 Theme Style
-----------------------------------------*/
.t-link {
    color: $dm-primary-two;

    &:hover {
        color: $dm-primary-one;
        text-decoration: none;
    }
}

.t-mr-md-20 {
    @include md-screen {
        margin-right: rem(20px);
    }
}

.t-head-container {
    max-width: rem(1620px);
    margin-left: auto;
    margin-right: auto;
}

.t-section-gap {
    padding: rem(100px) 0;

    @include lg-screen {
        padding: rem(200px) 0;
    }
}

.t-mb-25 {
    margin-bottom: rem(45px);
}

.t-container {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}

//Polash

.t-pt-100 {
    padding-top: rem(100px);

    @include lg-screen {
        padding-top: rem(150px);
    }
}

.t-pb-100 {
    padding-bottom: rem(100px);

    @include lg-screen {
        padding-bottom: rem(150px);
    }
}

.t-mt-100 {
    margin-top: rem(100px);

    @include lg-screen {
        margin-top: rem(150px);
    }
}

.t-mb-100 {
    margin-bottom: rem(100px);

    @include lg-screen {
        margin-bottom: rem(150px);
    }
}

.t-section-gap-pt {
    padding-top: rem(100px);

    @include lg-screen {
        padding-top: rem(200px);
    }
}

.t-section-gap-pb {
    padding-bottom: rem(100px);

    @include lg-screen {
        padding-bottom: rem(200px);
    }
}

.t-op-6 {
    opacity: .6;
}

.t-100vh {
    height: 100vh;
}

.t-bg {
    background: $dm-xlight-bg;
}

.t-logo {
    max-width: 155px;
    max-height: 55px;

    img {
        width: 100%;
    }
}

.t-nav-sticky {
    position: sticky;
    top: 0;
    z-index: 1020;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
}