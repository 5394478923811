/********* career oppoturnity css **************/
.career_oppoturnity{
    text-align: center;
    h2{
        font-size: 30px;
        font-weight: 600;
        line-height: 1.5;
        color: $black;
        margin-bottom: 28px;
    }
    p{
       font-size: 16px;
       line-height: 30px;
       color: $p_color; 
       margin-bottom: 0;
    }
}
.border_bottom{
    border-bottom: 1px solid #ddd;
    margin: 0 auto;
    max-width: 1110px;
}