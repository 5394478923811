.security-banner{
    background-image: url(../img/security-banner.png);
    background-repeat: no-repeat;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 72px;
        color: #fafafa;
        font-weight: 700;
        margin-bottom: 0;
        @include xs-screen{
            font-size: 40px;
        }
        @include sm-screen{
            font-size: 45px;
        }
        @include md-screen{
            font-size: 45px;
        }
        @include lg-screen{
            font-size: 72px;
        }
    }
}
.security-information{
    padding-top: 195px;
    padding-bottom: 125px;
    @media #{$mobile_device} {
        padding-top: 110px;
        padding-bottom: 60px;
    }
    @media #{$tablet_device} {
        padding-top: 110px;
        padding-bottom: 60px;
    }
    .single-security{
        margin-bottom: 70px;
        h3{
            font-size: 30px;
            color: #000;
            font-weight: 600;
            margin-bottom: 25px;
            @media #{$mobile_device} {
                font-size: 25px;
                margin-bottom: 15px;
            }
        }
        p{
            color: $dm-utility-five;
            font-size: 16px;
            line-height: 1.88;
            font-weight: 300;
            @media #{$mobile_device} {
               font-size: 14px;
            }
        }
        @media #{$mobile_device} {
            margin-bottom: 50px;
        }
    }
}

//Asad
.dm-security-img {
    @include lg-screen {
        border-bottom: 1px solid $dm-border;
    }
    &__container {
        padding: rem(80px) rem(65px);
        border-bottom: 1px solid $dm-border;
        @include md-screen {
            border-bottom: 0;
        }
        &--br {
            @include lg-screen {
                border-bottom: 0;
                border-left: 1px solid $dm-border;
                border-right: 1px solid $dm-border;
            }
        }
    }
    &__box {
        width: 200px;
        height: 80px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}