.service-banner{
    background-image: url(../img/service__banner.png);
    background-repeat: no-repeat;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 72px;
        color: #fafafa;
        font-weight: 700;
        margin-bottom: 0;
        @include xs-screen{
            font-size: 40px;
        }
        @include sm-screen{
            font-size: 45px;
        }
        @include md-screen{
            font-size: 45px;
        }
        @include lg-screen{
            font-size: 72px;
        }
    }
}

.service-area{
    padding-top: 196px;
    padding-bottom: 112px;
    @media #{$mobile_device} {
        padding-top: 100px;
        padding-bottom: 40px;
    }
    @media #{$tablet_device} {
        padding-top: 150px;
        padding-bottom: 80px;
    }
    .section-title{
        text-align: center;
        margin-bottom: 109px;
        h2{
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 35px;
            @media #{$mobile_device} {
                font-size: 25px;
                margin-bottom: 20px;
            }
        }
        p{
            font-size: 16px;
            color: $dm-utility-five;
            line-height: 1.88;
            @media #{$mobile_device} {
                font-size: 15px;
            }
        }
    }
    .single-service{
        text-align: center;
        margin-bottom: 80px;
        @media #{$mobile_device} {
            margin-bottom: 60px;
        }
        .service-icon{
            position: relative;
            z-index: 0;
            display: inline-block;
            width: 180px;
            height: 186px;
            line-height: 156px;
            margin-bottom: 37px;
            overflow: hidden;
           @media #{$mobile_device} {
            margin-bottom: 15px
        }
           .icon{
            position: relative;
            z-index: 2;
            top: 19px;
            left: -12px;
            @media #{$mobile_device} {
                height: 50px;
            }
            @media #{$tablet_device} {
                height: 65px;
            }
            
           }
           .icon-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .hover-img {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 17px;
            opacity: 0;
            transition: .3s;
        }
        }
        h3{
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 16px;
            @media #{$mobile_device} {
                font-size: 25px;
            }
            @media #{$tablet_device} {
                font-size: 24px;
            }
        }
        p{
            font-size: 16px;
            color: $dm-utility-five;
            line-height: 1.88;
            @media #{$mobile_device} {
                font-size: 14px;
            }
        }
        &:hover{
            .hover-img{
                opacity: 1;
            }
        }
    }
}
.service-icon{
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 180px;
    height: 186px;
    line-height: 156px;
    margin-bottom: 37px;
    overflow: hidden;
    &.1{
        position: relative;
    }
}

.service-icon::before {
    position: absolute;
    left: 0;
    top: 68px;
    content: "";
    width: 80px;
    height: 80px;
    z-index: 99;
    background-repeat: no-repeat;
    right: 0;
    margin: auto;
}
.service-icon.service-1::before{
    background-image: url(../img/service-100.png);
}
.service-icon.service-2::before{
    background-image: url(../img/service-101.png);
}
.service-icon.service-3::before{
    background-image: url(../img/service-102.png);
}
.service-icon.service-4::before{
    background-image: url(../img/service-103.png);
}
.service-icon.service-5::before{
    background-image: url(../img/service-104.png);
}
.service-icon.service-6::before{
    background-image: url(../img/service-105.png);
}
.service-icon.service-7::before{
    background-image: url(../img/service-106.png);
    left: -21px;
}
.service-icon.service-8::before{
    background-image: url(../img/service-107.png);
}
.service-icon.service-9::before{
    background-image: url(../img/service-108.png);
}
.service-icon.service-10::before{
    background-image: url(../img/service-109.png);
    left: -15px;
}
.service-icon.service-11::before{
    background-image: url(../img/service-110.png);
    width: 93px;
    left: -27px;
}
.service-icon.service-12::before{
    background-image: url(../img/service-111.png);
}
.service-icon.service-13::before{
    background-image: url(../img/service-112.png);
}
.service-icon.service-14::before{
    background-image: url(../img/service-113.png);
}
.service-icon.service-15::before{
    background-image: url(../img/service-114.png);
    left: -25px;
}

.single-service:hover .service-icon::before{
    -webkit-animation: toLeftFromRight 0.8s forwards;
	-moz-animation: toLeftFromRight 0.8s forwards;
	animation: toLeftFromRight 0.8s forwards;
}

@-webkit-keyframes toLeftFromRight {
	49% {
		-webkit-transform: translate(-100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}
@-moz-keyframes toLeftFromRight {
	49% {
		-moz-transform: translate(-100%);
	}
	50% {
		opacity: 0;
		-moz-transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes toLeftFromRight {
	49% {
		transform: translate(-100%);
	}
	50% {
		opacity: 0;
		transform: translate(100%);
	}
	51% {
		opacity: 1;
	}
}