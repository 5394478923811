/** our team css **/
.our_team{
    .single_team_member{
        text-align: center;
        img{
            width: 100%;
            margin-bottom: 30px;
        }
        h4{
            font-size: 21px;
            margin-bottom: 10px;
        }
    }
    .owl-dots{
        text-align: center;
        margin-top: 30px;
        .owl-dot{
            width: 15px;
            height: 15px;
            border-radius: 50px;
            display: inline-block;
            background-color: #f26922;
            margin: 0 10px;
            text-align: center;
            &:focus{
                outline: none;
            }
        }
        .owl-dot.active{
            height: 30px;
        }
    }
}
