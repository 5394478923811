/*---------------------------------------
   2.15 Modal
-----------------------------------------*/
.dm-modal {
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($dm-heading-text, .8);
    z-index: 2000;
    &__content {
        width: rem(555px);
        padding: rem(70px) rem(65px);
        position: relative;
        background: $white;
        &--reg {
            background: $white url(../img/dotted-left.png) bottom left no-repeat;
            background-size: 70%;
        }

        &-close {
            position: absolute;
            font-size: rem(50px);
            transform: rotate(45deg);
            top: rem(20px);
            right: rem(40px);
            cursor: pointer;
            transition: all .3s ease;
            &:hover {
                color: $dm-primary-one;
            }
        }
    }
}