/*---------------------------------------
   2.6 Signup Section
-----------------------------------------*/
.dm-signup {
    color: #fff;

    @include md-screen {
        display: flex;
        margin-bottom: rem(60px);
    }

    &--alt {
        @include md-screen {
            display: flex;
            margin-bottom: rem(60px);
        }
    }

    &__text {
        @include md-screen {
            width: 60%;
            text-align: left !important;
        }
    }

    &__title {
        color: #fff;
        margin-top: 0;

        &--alt {
            color: $dm-heading-text;
            margin-top: 0;
        }
    }

    &__btn {
        margin-bottom: rem(60px);

        @include md-screen {
            margin-bottom: 0;
            margin-left: auto;
        }
    }
}