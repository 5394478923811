/*---------------------------------------
   1.1 Variable
-----------------------------------------*/

//Fonts
$dm-font: 'Poppins', sans-serif;

//Color
$dm-body-text: #777;
$dm-body-text-light: #888;
$dm-heading-text: #000;
$white: #fff;
$dm-border: #ddd;
$dm-tab-btn: #eee;
$black: #000;
$p_color: #777;

$dm-primary-one: #f26922;
$dm-primary-two: #f6862a;

$dm-light-bg: #f3f3f3;
$dm-xlight-bg: #fafafa;

$dm-utility-one: #e1fcff;
$dm-utility-two: #f4ffd5;
$dm-utility-three: #ffe7e6;
$dm-utility-four: #fff9c6;
$dm-utility-five: #777;

//   responsive-variable
/* Normal desktop :1200px. */
$large_device:'(min-width: 1200px) and (max-width: 1500px)';

/* Normal desktop :992px. */
$mid_device:'(min-width: 992px) and (max-width: 1200px)';

/* Tablet desktop :768px. */
$tablet_device:'(min-width: 768px) and (max-width: 991px)';

/* small mobile :320px. */
$mobile_device:'(max-width: 767px)';

/* Large Mobile :480px. */
$large_mobile:'only screen and (min-width: 480px) and (max-width: 767px)';

//Polash
$medium_device : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab_device:'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 576px) and (max-width: 767px)';
$tab:'(max-width: 991px)';
$small_mobile:'(max-width: 576px)';