/*---------------------------------------
   2.16 Select
-----------------------------------------*/


.dm-form--modal {
    .nice-select {
        width: 100%;
        margin-bottom: rem(20px);
        font-size: rem(12px);
        color: $dm-body-text;
        font-weight: 300;
        text-transform: capitalize;
        border-radius: 0;
        border: 1px solid $dm-tab-btn;
        .option.selected {
            display: none;
        }

        li.option {
            position: relative;
            padding-right: rem(35px);
        }

        li.option.selected {
            font-weight: normal;
        }

        li.option.selected::after {
            color: $dm-body-text;
        }
    }
    .nice-select.open,
    .nice-select:active,
    .nice-select:focus {
        border-color: $dm-tab-btn;
    }

    .nice-select:hover {
        border-color: $dm-tab-btn;
    }

    .nice-select .list {
        margin-top: 0;
        border-radius: 0;
        box-shadow: 0 1px 7px rgba(#000, .1);
        border: none;
    }

}