.faq-banner{
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 36px;
        margin-bottom: 0;
        color: #fff;
        @media #{$mobile_device} {
            font-size: 20px;
            text-align: center;
        }
        @media #{$tablet_device} {
            font-size: 25px;
            text-align: center;
        }
        @include lg-screen {
            font-size: 72px;
        }
    }

}