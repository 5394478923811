/********* section tittle ***********/
.core_values {
    text-align: center;
    .single_core_values{
        @media #{$tab}{
            margin-bottom: 30px;
        }
        @media #{$medium_device}{
        
        }
        h4{
            font-size: rem(21px);
            line-height: rem(30px);
            margin-top: rem(45px);
            @media #{$tab}{
                margin-top: rem(25px);
            }
            @media #{$medium_device}{
            
            }
        }
        p{
            margin-top: rem(10px);
            margin-bottom: 0;
        }
    }
}