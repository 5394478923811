.dm-table-one {
    .table-head {
        background: $dm-primary-one;
        color: $white;
    }
    .table-body {
        font-size: 14px;
        color: $dm-body-text;
    }
    .table th, .table td {
        border-top: none
    }
    .table-br {
        border: 1px solid #fafafa;
    }
    .table-width {
        width: 50%;
    }
}
.tabl-1 {
     margin-bottom: 50px;
     @include lg-screen {
        margin-bottom: 100px;
     }
     
}
.big-table {
    padding: 60px 30px;
    
}
.table-2 {
    th {
        // padding: 30px;
        border: 1px solid $white;
    }
}
.tab-br {
    border: 1px solid rgba($white, .2);
}
.table-res {
     width: 100%;
}