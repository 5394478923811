/*---------------------------------------
   2.7 dm-tab
-----------------------------------------*/
.dm-tab {
    .nav-tabs {
        border-bottom: 1px solid rgba($white, .20);

        &--alt {
            border-bottom: 1px solid $dm-border;
        }
    }

    &__item {
        margin-top: rem(30px);
        text-align: left !important;

    }

    &__head {
        display: flex;

        &-icon {
            width: rem(30px);
            height: rem(30px);
            margin-right: rem(20px);

            &--payment {
                background: url(../img/dm-payment-icon.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-payments.png) center center no-repeat;
                    background-size: contain;
                }
            }

            &--bill {
                background: url(../img/bills.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-bills.png) center center no-repeat;
                    background-size: contain;
                }
            }

            &--purchase {
                background: url(../img/dm-bill-icon.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-purchases.png) center center no-repeat;
                    background-size: contain;
                }
            }

            &--insurance {
                background: url(../img/dm-insurance-icon.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-insurance.png) center center no-repeat;
                    background-size: contain;
                }
            }

            &--saving {
                background: url(../img/dm-savings-icon.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-savings.png) center center no-repeat;
                    background-size: contain;
                }
            }

            &--credit {
                background: url(../img/dm-credit-icon.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-credit.png) center center no-repeat;
                    background-size: contain;
                }
            }
            &--merchant {
                background: url(../img/merchant.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-credit.png) center center no-repeat;
                    background-size: contain;
                }
            }
            &--service {
                background: url(../img/partner.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-service.png) center center no-repeat;
                    background-size: contain;
                }
            }
            &--biller {
                background: url(../img/biller.png) center center no-repeat;
                background-size: contain;

                &--alt {
                    background: url(../img/sm-biller.png) center center no-repeat;
                    background-size: contain;
                }
            }
        }

        &-title-is {
            color: $white;
            text-transform: capitalize;

            &--alt {
                text-transform: capitalize;
            }
        }
    }

    &__body {
        color: $white;

        &--alt {
            color: $dm-body-text;
        }
    }
}