/*---------------------------------------
   2.14 Split
-----------------------------------------*/
.dm-split {
    position: relative;
    height: 100vh;
    display: flex;
}
.dm-screen {
    position: relative;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
    &:nth-child(1) {
        background: $dm-xlight-bg url(../img/dotted-b-right.png) center right no-repeat;
        background-size: contain;
    }
    &:nth-child(2) {
        background: $dm-light-bg;
        background: $dm-xlight-bg url(../img/dotted-b-left.png) center left no-repeat;
        background-size: contain;
    }
}
.dm-card {
    max-width: 80%;
    text-align: center;
    transition: all .5s;
    &__body {
        width: 100%;
    }
    &__img {
        margin-bottom: rem(60px);
        img {
            max-width: 100%;
        }
    }
    &__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all .5s ease;
        opacity: 0;
    }
}
.dm-card:hover .dm-card__content,
.dm-card__content:hover {
    opacity: 1;
    visibility: visible;
}

//===================
.xy {
    height: 0;
    width: 0;
    opacity: 0;
    
    @include lg-screen {
        height: 100vh;
        width: 100%;
        opacity: 1;
        display: flex;
        overflow-x: hidden;
    }
    &_z {
        display: flex;
        position: relative;
        background: $dm-light-bg url(../img/dotted-b-right.png) center right no-repeat;
        background-size: contain;
        flex-grow: 1;
        transition: 1s;
        &:hover {
            flex-grow: 7;
        }
        &__box {
            width: 300px;
            display: inline-block;
            text-align: center;
            margin: auto;
        }
    }
    &_y {
        display: flex;
        position: relative;
        background: $dm-xlight-bg url(../img/dotted-b-left.png) center left no-repeat;
        background-size: contain;
        flex-grow: 1;
        transition: 1s;
        &:hover {
            flex-grow: 7;
        }
    }
}