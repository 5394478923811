/*---------------------------------------
   2.4 Nav style
-----------------------------------------*/
.dm-nav {
    padding: rem(30px) 0;
    @include lg-screen {
        padding: rem(15px) 0;
    }
    .active {
        color: $dm-primary-one;
    }
    &__link {
        padding: rem(10px) 0;
        display: block;
        color: $dm-heading-text;
        @include lg-screen {
            padding-right: rem(40px);
            padding-top: 0;
            padding-bottom: 0;
        }
        &:hover {
            color: $dm-primary-one;
            text-decoration: none;
        }
        
    }
}