/*---------------------------------------
   1.2 Mixins
-----------------------------------------*/
//Media Breakpoint for Each Device

//Extra Small Screen
@mixin  xs-screen {
	@media screen and (max-width: 575px) {
		@content;
	}
}

//Small Screen (min-width: 576)
@mixin sm-screen {
	@media screen and (min-width: 576px) {
	  @content;
	}
  }
  //Medium Screen (min-width: 768)
  @mixin md-screen {
	@media screen and (min-width: 768px) {
	  @content;
	}
  }
  //Large Screen (min-width: 992)
  @mixin lg-screen {
	@media screen and (min-width: 992px) {
	  @content;
	}
  }
  //Xtra Large Screen (min-width: 1200)
  @mixin xl-screen {
	@media screen and (min-width: 1200px) {
	  @content;
	}
  }


