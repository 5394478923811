/*---------------------------------------
   2.12 Footer
-----------------------------------------*/
.footer {
    border-top: 1px solid $dm-border;
    background: $dm-xlight-bg url(../img/dm-logo-bg.png) center bottom no-repeat;
    background-size: contain;

    &__copy {
        margin-bottom: rem(60px);

        &-logo {
            margin-bottom: rem(50px);
            max-width: 155px;
            max-height: 55px;

            img {
                width: 100%;
            }
        }

        &-text {
            width: 80%;
            margin-bottom: rem(40px);
        }
    }
}