/*---------------------------------------
   2.1 Button Style
-----------------------------------------*/
.dm-btn {
    display: inline-block;
    
    transition: all .5s ease;
    &:hover {
        text-decoration: none;
        box-shadow: 0px 20px 50px 0 rgba(241, 89, 34, 0.5);
    }
}
.dm-p-btn {
    padding: rem(15px) rem(50px);
    font-size: rem(14px);
    background: linear-gradient(to right, $dm-primary-one, $dm-primary-two);
    text-transform: uppercase;
    font-weight: 500;
    color: $white;
    transition: all .5s ease;
    
    &:hover {
        
        color: $white;
    }
    &--alt {
        padding: rem(15px) rem(30px);
        position: relative;
        z-index: 1;
        background: $dm-heading-text;
        color: $dm-body-text-light;
        transition: all .3s ease;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0px 20px 50px 0 rgba(241, 89, 34, 0.5);
            background: linear-gradient(to right, $dm-primary-one, $dm-primary-two);
            opacity: 0;
            transition: all .3s ease;
            z-index: -1;
        }
        &:hover::before {
            opacity: 1;
        }
        &:hover {
            text-decoration: none;
            color: $white;

        }
        &-icon {
            font-size: rem(40px);
            margin-right: rem(15px);
        }
        &-title {
            transition: all .1s ease;
            color: $white;
            margin: 0 0 rem(3px);
        }
    }
}
.dm-btn-white {
    padding: rem(15px) rem(50px);
    font-size: rem(14px);
    background: $white;
    text-transform: uppercase;
    font-weight: 500;
    color: $dm-heading-text;
    &:hover {
        color: $dm-primary-one;
    }
}
.dm-button {
    display: inline-block;
    transition: all .3s ease;
    &:hover {
        text-decoration: none;
    }
}
.dm-tab-btn {
    padding: rem(15px) rem(30px);
    border-bottom:2px solid transparent;
    background: rgba($white, .1);
    font-size: rem(13px);
    font-weight: 500;
    text-transform: uppercase;
    color: $white;
    &:hover {
        border-bottom:2px solid $white;
        background: rgba($dm-heading-text, .15);
        color: $white;
    }
    
}
.dm-tab-btn.active {
    padding: rem(15px) rem(30px);
    border-bottom:2px solid $white;
    background: rgba($dm-heading-text, .15);
}
.dm-tab-btn--alt {
    padding: rem(15px) rem(30px);
    border-bottom:2px solid transparent;
    background: $dm-tab-btn;
    font-size: rem(13px);
    font-weight: 500;
    text-transform: uppercase;
    color: $dm-heading-text;
    &:hover {
        border-bottom:2px solid $white;
        background: $white;
        color: $dm-heading-text;
    }
    
}
.dm-tab-btn--alt.active {
    padding: rem(15px) rem(30px);
    border-bottom:2px solid $dm-primary-one;
    background: $white;
}