/*---------------------------------------
   1.4 Base Style
-----------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Poppins:300,500,600,700&display=swap');

html {
    font-size: 16px;
}

body {
    background-color: white;
    font-family: $dm-font;
    font-weight: 300;
    line-height: 1.67;
    color: $dm-body-text;
}

p {
    margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0 0 1rem;
    font-family: $dm-font;
    line-height: 1.15;
    color: $dm-heading-text;
}

h1 {
    margin-top: 0;
    margin-bottom: rem(30px);
    font-size: rem(72px);
    font-weight: 700;
}

h2 {
    font-size: rem(30px);
    font-weight: 600;
    text-transform: capitalize;
}

h3 {
    font-size: rem(21px);
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1.5;
}

h4 {
    font-size: rem(18px);
    font-weight: 600;
    text-transform: capitalize;
}

h5 {
    font-size: 1.5em;
    font-weight: 600;
}

//For Small Text

.dm-text-xxsm {
    font-size: rem(12px);
    font-weight: 300;
    text-transform: capitalize;

}
.dm-text-xsm {
    font-size: rem(13px);
    font-weight: 300;
    color:  $dm-body-text-light;

}
small,
.dm-text-sm {
    font-size: rem(14px);
    font-weight: 500;
    text-transform: uppercase;

}

.dm-text-md {
    font-size: rem(15px);
    font-weight: 500;
    text-transform: capitalize;
}

.dm-text-lg {
    font-size: rem(18px);
    font-weight: 300;

}
.bg-light {
    @include lg-screen {
        background: transparent !important;
    }
}