.partner__slider {
    padding: 100px 0;
}

.partner {
    &__logo {
        width: 150px;
        height: 200px;
        text-align: center;
        img {
            width: 100%;
        }

    }
    &-text {
        font-size: 18px;
        margin-top: 15px;
    }
}