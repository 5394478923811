.media-details-header{
    // background: #fafafa;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 65px;
    position: relative;
    z-index: 0;
    @include xs-screen{
        padding-bottom: 40px;
    }
    &::before{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        content: "";
        background: $dm-xlight-bg;
        height: 200%;
        z-index: -99;
    }
    h1{
        font-size: 72px;
        font-weight: 700;
        line-height: 1.17;
        margin-bottom: 36px;
        br{
            @include xs-screen{
                display: none;
            }
            @include sm-screen{
                display: none;
            }
        }
        @include xs-screen{
            font-size: 24px;
            margin-bottom: 18px;
        }
        @include sm-screen{
            font-size: 35px;
            margin-bottom: 18px;
        }
        @include md-screen{
            font-size: 35px;
        }
    }
    .date{
        span{
            font-size: 13px;
            color: $dm-heading-text;
            font-family: $dm-font;
            font-weight: 300;
        }
    }
}
.media-main-wrapper{
    position: relative;
    z-index: 0;
// details-banner
// .media-dtails-banner{
//     background-image: url(../img/news.png);
//     height: 500px;
//     background-size: cover;
//     background-position: center;
// }
.media-dtails-banner img {
	width: 100%;
}
// media-info-start
.media-details-info{
    margin-top: 42px;
    margin-bottom: 75px;
    @include xs-screen{
       margin-bottom: 40px;
     }
     @include sm-screen{
        margin-bottom: 40px;
    }
    p{
        font-size: 16px;
        color: #707070;
        line-height: 1.88;
        font-weight: 300;
        @include xs-screen{
            font-size: 14px;
        }
    }
    .first-para {
        margin-bottom: 25px;
    }
    .second-para{
        margin-bottom: 25px;
    }
    .media-info-list{
        ul{
            padding: 0;
            li{
                list-style: none;;
                p{
                    color: #000;
                    span{
                        width: 6px;
                        height: 6px;
                        background: #000;
                        color: #000;
                        display: inline-block;
                        border-radius: 50%;
                        position: relative;
                        bottom: 3px;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .list-para{
        p{

        }
    }
    .media-info-list{
        padding: 10px 0;
        p{
            &:first-child {
                margin-bottom: 25px;
            }
        }
    }
}
}

.news-share{
    padding:0 0 200px 0;
    @include xs-screen{
        padding:0 0 50px 0;
     }
     @include md-screen{
        padding:0 0 50px 0;
     }
     @include sm-screen{
        padding:0 0 50px 0;
    }
     @include lg-screen{
        padding:0 0 200px 0;
    }
    .row{
        align-items: center;
        @include xs-screen{
            align-items: inherit;
        }
    }
    .news-border {
        border-top: 1px solid $dm-border;
        padding-bottom: 50px;
    }
    .share-social-news{
        @include xs-screen{
            text-align: center;
            margin-bottom: 15px;
        }
        @include sm-screen{
            text-align: center;
            margin-bottom: 15px;
        }
        span{
            color: #707070;
            font-size: 16px;
            font-weight: 300;
            font-family: $dm-font;
            margin-right: 37px;
            @include xs-screen{
                margin-right: 15px;
            }
            @include md-screen{
                margin-right: 30px;
             }
             @include sm-screen{
                margin-right: 30px;
            }
        }
        .social-media{
            display: inline-block;
            li{
                display: inline-block;
                margin-right: 25px;
                @include xs-screen{
                    margin-right: 10px;
                }
                a{
                    color: $dm-border;
                    font-size: 14px;
                    transition: .3s;
                    &:hover{
                        color: #ff573d;
                    }
                }
            }
        }
    }
    .news-buttton{
        text-align: center;
        @include xs-screen{
           text-align: center;
        }
        @include sm-screen{
            text-align: center;
        }
        @include lg-screen{
            text-align: right;
        }

        a {
            background: $dm-xlight-bg;
            color: #000;
            padding: 15px 30px;
            display: inline-block;
            font-size: 13px;
            font-weight: 500;
            font-family: $dm-font;
            transition: .3s;
            @include xs-screen{
                padding: 10px 20px;
             }
            &.active{
                margin-left: 16px;
                background-image: linear-gradient(to right, #f26922, #f6862a);
                color: $white;
                transition: .3s;
            }
            &:hover{
                background-image: linear-gradient(to right, #f26922, #f6862a);
                box-shadow:  0px 20px 50px 0 rgba(241, 89, 34, 0.5);
                color: $white;
                text-decoration: none;
            }
        }
    }
}
