/*---------------------------------------
   2.13 dm-Form
-----------------------------------------*/
.dm-form {
    &--modal {
        margin-top: rem(50px);
    }

    &__input {
        width: 100%;
        padding: rem(15px);
        margin-bottom: rem(10px);
        display: block;
        border: 1px solid $dm-tab-btn;
        font-size: rem(12px);
        color: $dm-body-text-light;
        font-weight: 300;
        text-transform: capitalize;

        &::placeholder {

            font-size: rem(12px);
            color: $dm-body-text-light;
            font-weight: 300;
            text-transform: capitalize;

        }

        &:focus {
            outline: none;
        }
        &--modal {
            margin-bottom: rem(20px);
        }
    }

    &__submit {
        width: 100%;
        padding: rem(15px) 0;
        display: block;
        border: none;
        background: linear-gradient(to right, $dm-primary-one, $dm-primary-two);
        font-size: rem(14px);
        font-weight: 500;
        text-transform: uppercase;
        color: $white;
        transition: all .3s ease;

        &:hover {
            box-shadow: 0px 10px 30px 0 rgba(241, 89, 34, 0.5);
        }

    }
}