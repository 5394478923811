/** dmoney culture css**/
.dmoney_culture{
    position: relative;
    overflow: hidden;
    &_img {
        width: 100%;
        height: auto;
    }
    .col-lg-6{
        padding-right: 0;
        padding-left: 0;
        @media #{$tab}{
            padding-right: 15px;
            padding-left: 15px;
        }
        @media #{$medium_device}{
        
        }
        
    }
    .career_oppoturnity_text{
        padding-left: 31%;
        padding-right: 30px;
        @media #{$tab}{
            padding-left: 0;
        }
        @media #{$medium_device}{
            padding-left: 10%;
        }
        .career_oppoturnity_tittle{
            margin-bottom: 88px;
            h2{
                font-size: 72px;
                line-height: 84px;
                font-weight: 700;
                @media #{$tab}{
                    font-size: 30px;
                    line-height: 35px;
                }
                @media #{$medium_device}{
                
                }
            }
            p{
                margin-top: 35px;
                margin-bottom: 0;
                @media #{$tab}{
                    font-size: 16px;
                    line-height: 30px;
                }
                @media #{$medium_device}{
    
                }
                
            }
        }
        .career_oppoturnity_details{
           span{
               font-weight: 600;
           }
        }
    } 
}