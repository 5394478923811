/** section tittle css **/
.section_tittle{
    margin-bottom: rem(100px);
    text-align: center;
    @media #{$tab}{
        margin-bottom: rem(40px);
    }
    @media #{$medium_device}{

    }
    h2{
        font-size: rem(72px);
        line-height: rem(84px);
        color: $black;
        margin-bottom: rem(20px);
        @media #{$tab}{
            font-size: rem(30px);
            line-height: rem(40px);
            margin-bottom: rem(10px);
        }
        @media #{$medium_device}{

        }
    }
    p{
        font-size: rem(16px);
        line-height: rem(30px);
    }
}