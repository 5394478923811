/**  career contact scss**/
.career_bg{
    background-image: url(../img/career_banner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}
.career_contact{
    background-image: url(../img/let-us-know.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        content: "";
        background-color: $black;
        opacity: 0.2;
    }
    .career_contact_text{
       
        h2{
            font-size: 72px;
            line-height: 84px;
            color: $white;
            margin-bottom: 30px;
            @media #{$small_mobile}{
                font-size: 30px;
                line-height: 40px;
                padding: 0;
            }
            @media #{$large_mobile}{
                font-size: 35px;
                line-height: 45px;
                padding: 0;
            }
    
            @media #{$tab_device}{
                font-size: 40px;
                line-height: 50px;
                padding: 0;
            }
            @media #{$medium_device}{
                font-size: 60px;
                line-height: 70px;
            }
        }
        p{
            color: $white;
        }
    }
    .career_contact_form{
        padding: 75px 60px;
        background-color: $white;
        @media #{$small_mobile}{
            padding: 40px 15px;
        }
        @media #{$large_mobile}{
            padding: 40px 35px;
        }

        @media #{$tab_device}{
            padding: 50px 45px;
        }
        @media #{$medium_device}{
        
        }
        h3{
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: #262626;
            @media #{$small_mobile}{
                margin-bottom: 0;
                padding: 0;
            }
            @media #{$large_mobile}{
                
            }
    
            @media #{$tab_device}{
                
            }
        }
        .dm-form__submit{
            &:hover{
                box-shadow: 0px 20px 50px 0 rgba(241, 89, 34, 0.5);
            }
            
        }
    }
    .custom-file-label{
        width: 100%;
        padding: .9375rem;
        margin-bottom: .625rem;
        display: block;
        border: 1px solid #eee;
        font-size: .75rem;
        color: #888;
        font-weight: 300;
        text-transform: capitalize;
        height: auto;
        border-radius: 0;
    }
    .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: auto;
        margin: 0;
        opacity: 0;
    }
    .custom-file{
        height: 50px;
        margin-bottom: 4px;
    }
    .custom-file-label::after {
        top: 9px;
        font-family: 'themify';
        color: #495057;
        content: "\e66b" !important;
        background-color: transparent;
        border-left: 0;
    }
}

.dm-modal__content{
    .custom-file-label{
        width: 100%;
        padding: .9375rem;
        margin-bottom: .625rem;
        display: block;
        border: 1px solid #eee;
        font-size: .75rem;
        color: #888;
        font-weight: 300;
        text-transform: capitalize;
        height: auto;
        border-radius: 0;
    }
    .custom-file-input {
        position: relative;
        z-index: 2;
        width: 100%;
        height: auto;
        margin: 0;
        opacity: 0;
    }
    .custom-file{
        height: 50px;
        margin-bottom: 4px;
    }
    .custom-file-label::after {
        top: 9px;
        font-family: 'themify';
        color: #495057;
        content: "\e66b" !important;
        background-color: transparent;
        border-left: 0;
    }
}