/*---------------------------------------
   2.8 Pride Section Style
-----------------------------------------*/
.dm-pride {
    &__img {
        margin-bottom: rem(40px);
    }
    &__text {
        text-align: center;
        margin-bottom: rem(60px);
        @include lg-screen {
            text-align: left;
        }
    }
    &__box {
        padding: rem(125px) rem(75px) rem(45px) rem(45px);
        @include lg-screen {
            padding: rem(125px) rem(35px) rem(45px) rem(35px);
        }
        @include xl-screen {
            padding: rem(125px) rem(75px) rem(45px) rem(45px);
        }
        margin-bottom: rem(35px);
        &--one {
            background: $dm-utility-one;
        }
        &--two {
            background: $dm-utility-two;
        }
        &--three {
            background: $dm-utility-three;
        }
        &--four {
            background: $dm-utility-four;
        }
    }
    
}
.dm_pride_box_color_1{
    background-color: #fafafa;
}
.dm_pride_box_color_2{
    background-color: #f3f3f3 !important;
}