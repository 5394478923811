/*---------------------------------------
   2.9 list style
-----------------------------------------*/
.dm-list {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
        display: inline-block;
    }
    &__link {
        display: inline-block;
        &:hover {
            text-decoration: none;
        }
    }
}