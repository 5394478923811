.terms-condition.accordion_part.t-section-gap{
    .accordion-text{
        p{
            font-size: 16px;
            line-height: 1.88;
        }
    }
    .single_accordion_part {
        padding-top: 35px;
        .card-body h5 {
            font-size: 18px;
            margin-bottom: 14px;
            line-height: 18px;
        }
        li{
            span{
                width: 4px;
                height: 4px;
                background:#777777;
                display: inline-block;
                margin-right: 11px;
                border-radius: 50%;
                position: relative;
                top: -3px;
            }
        }
        &.second-part{
            padding-top: 0;
            h5{
                margin-top: 27px;
                margin-bottom: 15px;
            }
        }
        p{
            margin-bottom: 10px;
        }
    }

}