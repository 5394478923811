.section-pading{
    padding-top: 200px;
    padding-bottom: 200px;
    @include xs-screen{
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
.media-banner {
	background-image: url(../img/banner__media.png);
    background-repeat: no-repeat;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
        font-size: 72px;
        color: #fafafa;
        font-weight: 700;
        margin-bottom: 0;
        @include xs-screen{
            font-size: 38px;
        }
        @include md-screen{
            font-size: 45px;
        }
        @include lg-screen{
            font-size: 72px;
        }
    }
}

.meadia-blog-area{
    .single-blog{
        margin-bottom: 70px;
        .single-blog-thumb{
           margin-bottom: 23px;
            img{
                width: 100%;
            }
        }
        .blog-content{
            h3{
               a{
                font-size: 21px;
                font-family: $dm-font;
                line-height: 1.43;
                font-weight: 600;
                color: $dm-heading-text;
                text-decoration: none;
                transition: .3s;
                @include xs-screen{
                    font-size: 20px;
                }
                @include md-screen{
                    font-size: 20px;
                }
                &:hover{
                    text-decoration: none;
                    color: $dm-primary-one;
                }
               }
            }
            p{
                color: $dm-body-text;
                font-size: 16px;
                font-weight: 300;
                line-height: 1.88;
                margin-bottom: 15px;
            }
            .blog-date {
                font-size: 13px;
                color: $dm-heading-text;
                font-weight: 300;
                transition: .3s;
                font-family: $dm-font;
                &:hover{
                    color: $dm-primary-one;
                    text-decoration: none;
                }
            }
        }
    }
    .blog-btn{
        text-align: center;
        margin-top: 4px;
        .dm-p-btn {
            padding: 19px 51px;
            font-size: 14px;
            background: linear-gradient(to right, #f26922, #f6862a);
            text-transform: capitalize;
            font-weight: 500;
            color: #fff;
            @include xs-screen{
                padding: 12px 25px;
            }
            @include sm-screen{
                padding: 15px 25px;
            }
            @include md-screen{
                padding: 15px 25px;
            }
        }
    }

}
