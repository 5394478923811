/*---------------------------------------
   2.5 Individual Section
-----------------------------------------*/
.dm-individual {
    position: absolute;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, $dm-primary-one, $dm-primary-two);

    &--alt {
        background: $dm-light-bg;

        &-x {
            background: $dm-xlight-bg;
        }
    }

    &__bg {
        // background: url(../img/dotted-bg.png) bottom right no-repeat;
        // background-size: contain;

        &--alt {
            // background: url(../img/dotted-b-right.png) bottom right no-repeat;
            // background-size: contain;
        }
    }

}