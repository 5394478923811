/*---------------------------------------
   2.11 Footer Nav
-----------------------------------------*/
.dm-footer-nav {
    margin-bottom: rem(15px);
    text-transform: capitalize;
    color: $dm-body-text;
    &:hover {
        color: $dm-primary-one;
    }

}